import { Component } from '@angular/core';
import {environment} from "../../../environments/environment";

@Component({
  selector: 'crm-callback',
  standalone: true,
  templateUrl: './callback.component.html',
  styleUrl: './callback.component.scss'
})
export class CallbackComponent {

  protected readonly environment = environment;

  redirect(event: Event) {
    event.preventDefault();
    window.location.href = environment.clientUrl;
  }
}
