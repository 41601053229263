<main class="min-h-screen w-full">
  <!--Header-->
  <header class="sticky top-0 flex justify-items-center justify-center bg-neutral-0
  border-b-[1px] border-b-neutral-300 h-[70px]">
    <img ngSrc="assets/logo-v2.svg" alt="CRM Logo" height="32" width="134" priority="priority">
  </header>
  <!--Header-->

  <!--GDPR public page body-->
  @if (!(showInfoCard | async)) {
    <!-- GDPR form-->
    <div class="h-full form-header">
      <!-- Header public image -->
      <div class="relative w-full">
        <!-- Header public image -->
        <img src="assets/media/header-public.png" alt="Public Header" class="w-full h-auto object-cover" />
        <!-- ATOZ logo in bottom-right -->
        <div class="absolute bottom-4 right-4">
          <img src="assets/media/logos/atoz-logo-white.svg" alt="ATOZ Logo" class="h-[40px]" />
        </div>
      </div>
    <div class="w-full text-center">
      <h3 class="font-semibold text-[29px] leading-[34px]">Tick the boxes to stay informed:</h3>
    </div>
    <!-- Header public image -->
    <form class="flex flex-col gap-[32px] lg:px-0 px-[16px] sm:w-full sm:px-[32px] mx-auto pt-[25px] pb-[15px] mb-[50px] text-base text-neutral-900"
          [formGroup]="gdprConsentForm">
      <!--GDPR consent form introduction-->
      <div class="flex flex-col gap-[16px]">
        <div class="flex flex-col gap-[8px]">
            <p>ATOZ would like to stay connected with you!</p>
            <p>
              In compliance with the provisions of EU Regulation n°2016/679 of 27 April 2016 on the protection
              of natural persons with regard to the processing of personal data and on the free movement
              (the General Data Protection Regulation) in effect as from 25 May 2018,
              we are required to ask you for your express consent to receive certain emails from us.
            </p>
            <p>
              In addition, please note that we use EU-based communication platform provider, dotdigital,
              for the purpose of sharing our marketing communications and optimising their release.
            </p>
          </div>
        </div>
        <!--GDPR consent form introduction-->

        <!--GDPR form-->
      <div class="flex flex-col gap-[32px]">
        <!--Dot Digital consent-->
        <div class="flex flex-col gap-[16px]">
          <h4 class="font-semibold text-[14px]">
            Please choose one of the following:
            <span class="text-red-600">*</span>
          </h4>
          <div class="flex flex-col gap-[8px]">
            <div class="flex">
              <p-radioButton
                inputId="dotDigitalConsentAccorded"
                [value]="true"
                formControlName="dotDigitalConsent"/>
              <label [for]="'dotDigitalConsentAccorded'" class="ml-[10px]">
                Yes, I consent to receiving marketing communications from ATOZ Tax Advisers and ATOZ Group companies
              </label>
            </div>

            <div class="flex">
              <p-radioButton
                inputId="dotDigitalConsentNotAccorded"
                [value]="false"
                formControlName="dotDigitalConsent"/>
              <label [for]="'dotDigitalConsentNotAccorded'" class="ml-[10px]">
                No, I do not consent to receiving marketing communications from ATOZ Tax Advisers and ATOZ Group
                companies
              </label>
            </div>
            <!--dot digital consent-->
            @if (isControlHasError(GdprFormControlNameEnum.DOT_DIGITAL_CONSENT, ValidationType.REQUIRED)) {
              <small class="p-error">{{ InputValidationErrorMessageEnum.FIELD_REQUIRED }}</small>
            }
            <!--dot digital consent-->
          </div>
        </div>
        <!--Dot Digital consent-->

        @if (gdprConsentForm.get(GdprFormControlNameEnum.DOT_DIGITAL_CONSENT)?.value) {
          <!--Information to receive from ATOZ-->
          <div class="flex flex-col gap-[16px]">
            <h4 class="font-medium text-base">
              What type of information would you like to receive from ATOZ?
            </h4>
            <div class="flex flex-col gap-[8px]">
              <!--News consent checkbox-->
              <div class="flex items-center select-none ml-0.5">
                <div class="crm-checkbox">
                  <input type="checkbox" class="w-0 h-0" id="atoz-news-checkbox" formControlName="atozNewsConsent"
                         #atozNewsConsent/>
                  <label for="atoz-news-checkbox" style="--size: 15px">
                    <svg width="13" height="10" viewBox="0 0 12 9">
                      <path d="M11.0425 1.25L4.16748 8.125L1.04248 5"/>
                    </svg>
                  </label>
                </div>
                <p class="pl-[10px] cursor-pointer" (click)="atozNewsConsent.click()">
                  News from ATOZ (Such as our Insights newsletter, our alerts and greeting cards)
                </p>
              </div>
              <!--News consent checkbox-->

              <!--Events consent checkbox-->
              <div class="flex items-center select-none ml-0.5">
                <div class="crm-checkbox">
                  <input type="checkbox" class="w-0 h-0" id="atoz-events-checkbox" formControlName="atozEventsConsent"
                         #atozEventsConsent/>
                  <label for="atoz-events-checkbox" style="--size: 15px">
                    <svg width="13" height="10" viewBox="0 0 12 9">
                      <path d="M11.0425 1.25L4.16748 8.125L1.04248 5"/>
                    </svg>
                  </label>
                </div>
                <p class="pl-[10px] cursor-pointer" (click)="atozEventsConsent.click()">
                  Invitations to events (Such as Networking events, seminars, webinars and training)
                </p>
              </div>
              <!--Events consent checkbox-->

            </div>
          </div>
          <!--Information to receive from ATOZ-->

            <!--Deveco consent checkbox-->
          <div class="flex flex-col gap-[16px]">
            <h4 class="font-medium text-base">
              With the development of our ESG services, we would like to know if you wish to receive specific
              communications from Deveco, an ATOZ company:
              <span class="text-red-600">*</span>
            </h4>
            <div class="flex flex-col gap-[8px]">
              <div class="flex items-center">
                <p-radioButton id="deveco-yes" name="devecoConsent" [value]="true" formControlName="devecoConsent"/>
                <label for="deveco-yes" class="ml-[10px]">Yes, I would like to receive ESG-specific
                  communications</label>
              </div>
              <div class="flex items-center">
                <p-radioButton id="deveco-no" name="devecoConsent" [value]="false" formControlName="devecoConsent"/>
                <label for="deveco-no" class="ml-[10px]">No, I do not wish to receive ESG-specific
                  communications</label>
              </div>
            </div>
            @if (isControlHasError(GdprFormControlNameEnum.DEVECO_CONSENT, ValidationType.REQUIRED)) {
              <small class="p-error">{{ InputValidationErrorMessageEnum.FIELD_REQUIRED }}</small>
            }
          </div>
          <!--Deveco consent checkbox-->

            <!--Information to receive from Deveco-->
          @if (gdprConsentForm.get(GdprFormControlNameEnum.DEVECO_CONSENT)?.value) {
            <div class="flex flex-col gap-[16px]">
              <h4 class="font-medium text-base">
                What type of information would you like to receive from Deveco?
              </h4>
              <div class="flex flex-col gap-[8px]">
                <!--News consent checkbox-->
                <div class="flex items-center select-none ml-0.5">
                  <div class="crm-checkbox">
                    <input type="checkbox" class="w-0 h-0" id="deveco-news-checkbox" formControlName="devecoNewsConsent"
                           #devecoNewsConsent/>
                    <label for="deveco-news-checkbox" style="--size: 15px">
                      <svg width="13" height="10" viewBox="0 0 12 9">
                        <path d="M11.0425 1.25L4.16748 8.125L1.04248 5"/>
                      </svg>
                    </label>
                  </div>
                  <p class="pl-[10px] cursor-pointer" (click)="devecoNewsConsent.click()">
                    News from Deveco (such as our ESG newsletter, our ESG-specific Alerts)
                  </p>
                </div>
                <!--News consent checkbox-->

                <!--Events consent checkbox-->
                <div class="flex items-center select-none ml-0.5">
                  <div class="crm-checkbox">
                    <input type="checkbox" class="w-0 h-0" id="deveco-events-checkbox"
                           formControlName="devecoEventsConsent" #devecoEventsConsent/>
                    <label for="deveco-events-checkbox" style="--size: 15px">
                      <svg width="13" height="10" viewBox="0 0 12 9">
                        <path d="M11.0425 1.25L4.16748 8.125L1.04248 5"/>
                      </svg>
                    </label>
                  </div>
                  <p class="pl-[10px] cursor-pointer" (click)="devecoEventsConsent.click()">
                    Invitations to events (including technical & networking events and trainings)
                  </p>
                </div>
                <!--Events consent checkbox-->

              </div>
            </div>
          }
          <!--Information to receive from Deveco-->

          }
          <div class="flex flex-col gap-[16px]">
            <!--Information to receive from ATOZ-->
            <h4 class="font-semibold text-[14px]">
              Please provide your information:
              <span class="text-red-600">*</span>
            </h4>
            <!-- User data form -->
            <div class="lg:w-[320px] sm:w-full md:w-1/2">
              <div class="flex flex-col gap-2" >
                <!--Title -->
                <div class="flex flex-col gap-1">
                  <label for="title" class="font-medium text-[14px]">Title</label>
                  <p-dropdown
                    id="title"
                    placeholder="Select"
                    [options]="titleOptions"
                    formControlName="title"
                  />
                  @if (isControlHasError(GdprFormControlNameEnum.TITLE, ValidationType.REQUIRED)) {
                    <span class="p-error">{{ InputValidationErrorMessageEnum.FIELD_REQUIRED }}</span>
                  }
                </div>
                <!--Title -->

                <!--First name-->
                <div class="flex flex-col gap-1">
                  <label for="firstName" class="font-medium text-[14px]">First name</label>
                  <div class="crm-input">
                    <input formControlName="firstName"
                           placeholder="Enter text" type="text" id="firstName">
                    @if (!getFormControl(GdprFormControlNameEnum.FIRSTNAME).disabled) {
                      <span [inlineSVG]="'assets/icons/pen.svg'" class="gray-icon"></span>
                    }
                  </div>
                  @if (isControlHasError(GdprFormControlNameEnum.FIRSTNAME, ValidationType.REQUIRED)) {
                    <span class="p-error">{{ InputValidationErrorMessageEnum.FIELD_REQUIRED }}</span>
                  }
                </div>
                <!--First name-->

                <!--Last name-->
                <div class="flex flex-col gap-1">
                  <label for="lastName" class="font-medium text-[14px]">Last name</label>
                  <div class="crm-input">
                    <input formControlName="lastName"
                           placeholder="Enter text" type="text" id="lastName">
                    @if (!getFormControl(GdprFormControlNameEnum.LASTNAME).disabled) {
                      <span [inlineSVG]="'assets/icons/pen.svg'" class="gray-icon"></span>
                    }
                  </div>
                  @if (isControlHasError(GdprFormControlNameEnum.LASTNAME, ValidationType.REQUIRED)) {
                    <span class="p-error">{{ InputValidationErrorMessageEnum.FIELD_REQUIRED }}</span>
                  }
                </div>
                <!--Last name-->

                <!--Job title-->
                <div class="flex flex-col gap-1">
                  <label for="jobTitle" class="font-medium text-[14px]">Job title</label>
                  <div class="crm-input">
                    <input formControlName="jobTitle"
                           placeholder="Enter text" type="text" id="jobTitle">
                    @if (!getFormControl(GdprFormControlNameEnum.JOB_TITLE).disabled) {
                      <span [inlineSVG]="'assets/icons/pen.svg'" class="gray-icon"></span>
                    }
                  </div>
                  @if (isControlHasError(GdprFormControlNameEnum.JOB_TITLE, ValidationType.REQUIRED)) {
                    <span class="p-error">{{ InputValidationErrorMessageEnum.FIELD_REQUIRED }}</span>
                  }
                </div>
                <!--Job title-->

                <!--Company-->
                <div class="flex flex-col gap-1">
                  <label for="company" class="font-medium text-[14px]">Company</label>
                  <div class="crm-input">
                    <input formControlName="companyName"
                           placeholder="Enter text" type="text" id="company">
                    @if (!getFormControl(GdprFormControlNameEnum.COMPANY_NAME).disabled) {
                      <span [inlineSVG]="'assets/icons/pen.svg'" class="gray-icon"></span>
                    }
                  </div>
                  @if (isControlHasError(GdprFormControlNameEnum.COMPANY_NAME, ValidationType.REQUIRED)) {
                    <small class="p-error">{{ InputValidationErrorMessageEnum.FIELD_REQUIRED }}</small>
                  }
                </div>
                <!--Company-->

              </div>
            </div>
            <!-- User data form -->
          </div>
          <!--Information to receive from ATOZ-->
        </div>
        <!--GDPR form-->

        <!--Submit button-->
        <div class="flex flex-col gap-1">
          <div>
            <button (click)="submit()"
                    [disabled]="shouldDisableSubmitButton()"
                    type="submit"
                    class="btn-primary btn-positioning">
              Submit
            </button>
          </div>
        </div>
        <!--Submit button-->
      </form>
    </div>
    <!-- GDPR form-->
  } @else {
    <div class="flex h-[calc(100vh-100px)] items-center justify-center">
      <crm-info-card [config]="infoCardConfig">
        <!--Link to home page-->
        <div class="flex gap-[4px]" card-action>
          <a  class="text-primary-600 text-base font-medium" href="https://www.atoz.lu/">
            Go to homepage
          </a>
          <span class="font-medium" [inlineSVG]="'assets/icons/arrow-up-right.svg'"></span>
        </div>
        <!--Link to home page-->
      </crm-info-card>

    </div>
  }
  <!--GDPR public page body-->

  <!--Footer-->
  <footer class="flex justify-center w-full bg-neutral-200 h-[30px]">
    <div class="grid content-center">
      <p class="text-neutral-900 text-s font-semibold">
        {{today | date:'yyyy'}} © ATOZ Tax Advisers.
      </p>
    </div>
  </footer>
  <!--Footer-->
</main>
