import {Component, OnInit} from '@angular/core';
import {AsyncPipe, NgIf, NgOptimizedImage, TitleCasePipe} from "@angular/common";
import {RouterLink, RouterLinkActive} from "@angular/router";
import {OidcSecurityService} from "angular-auth-oidc-client";
import {InlineSVGModule} from "ng-inline-svg-2";
import {TooltipModule} from "primeng/tooltip";
import {AuthService} from "../../../../auth/auth.service";
import {BehaviorSubject, Observable} from "rxjs";
import {User} from "../../../../shared/models/user.model";
import {AvatarUser} from "../../../../shared/components/avatar-component/models/avatar-user.model";
import {
  AvatarSingularComponent
} from "../../../../shared/components/avatar-component/avatar-singular/avatar-singular.component";
import {UtilsService} from "../../../../shared/services/global/utils-service.service";
import {UserRole} from "../../../../shared/enums/user-role.enum";
import {NgxZendeskWebwidgetService} from "ngx-zendesk-webwidget";
import {DialogService} from "primeng/dynamicdialog";
import {ChangelogModalComponent} from "../../../../shared/modals/changelog/changelog-modal.component";
import {NewBadgeService} from "../../../../shared/services/new-badge.service";

@Component({
  selector: 'crm-sidebar',
  standalone: true,
  imports: [
    NgOptimizedImage,
    RouterLink,
    RouterLinkActive,
    AsyncPipe,
    InlineSVGModule,
    TooltipModule,
    AvatarSingularComponent,
    TitleCasePipe,
    NgIf,
  ],
  providers: [
    DialogService
  ],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss'
})
export class SidebarComponent implements OnInit {
  currentUser$: Observable<User>;
  isZendeskOpen = false;
  new_badge_items = [
    {id: 'dashboard', isNew: true},
    {id: 'changelog', isNew: true},
  ];
  unreadMessages = new BehaviorSubject<number>(0);
  protected readonly UserRole = UserRole;

  constructor(private readonly oidcSecurityService: OidcSecurityService,
              private readonly authService: AuthService,
              public readonly utilsService: UtilsService,
              public readonly _NgxZendeskWebwidgetService: NgxZendeskWebwidgetService,
              public readonly dialogService: DialogService,
              private readonly newBadgeService: NewBadgeService
  ) {
    this.currentUser$ = authService.currentUser;
    this._NgxZendeskWebwidgetService.initZendesk().then(() => {
      this._NgxZendeskWebwidgetService.zE('messenger:on', 'close', () => {
        this._NgxZendeskWebwidgetService.zE('messenger', 'hide');
        this.isZendeskOpen = false;
      });

      this._NgxZendeskWebwidgetService.zE('messenger:on', 'unreadMessages', (count: any) => {
        this.unreadMessages.next(count)
      });
    });
  }

  ngOnInit() {
    // Initialize button states
    this.new_badge_items.forEach((button) => {
      button.isNew = !this.newBadgeService.getButtonState(button.id);
    });
  }

  // This function is used to toggle a sublist of links in the sidebar.
  toggleSublist(sublist: HTMLElement, arrowIcon: HTMLElement) {
    sublist.classList.toggle('hideSubList');
    arrowIcon.classList.toggle('-rotate-180');
  }

  getCurrentUserAvatar() {
    const currentUser = this.authService.currentUser.value;
    return new AvatarUser(currentUser.person);
  }

  logout() {
    this.oidcSecurityService.logoffAndRevokeTokens().subscribe(() => {
      location.reload();
    });
  }

  onSupportButtonClick() {
    if (this.isZendeskOpen) {
      this._NgxZendeskWebwidgetService.zE('messenger', 'hide');
      this.isZendeskOpen = false;
      return;
    } else {
      this._NgxZendeskWebwidgetService.zE('messenger', 'show');
      this._NgxZendeskWebwidgetService.zE('messenger', 'open');
      this.isZendeskOpen = true;
    }
  }

  // Function to check if the "new" badge should be displayed for a specific button
  shouldDisplayNewBadge(buttonId: string): boolean {
    return !this.newBadgeService.getButtonState(buttonId);
  }

  openChangelogFeature() {
    // Update the button state in local storage
    this.newBadgeService.setButtonState('changelog', true);

    this.dialogService.open(ChangelogModalComponent, {
      width: '700px',
      height: '90vh',
      closable: false,
      focusOnShow: false,
      dismissableMask: true
    });
  }
}
