import {Injectable} from '@angular/core';
import {APP_VERSION} from "../../version";

@Injectable({
  providedIn: 'root',
})
export class NewBadgeService {
  private appVersion = APP_VERSION;

  constructor() {
    this.checkAppVersion();
  }

  // Check if the app version has changed and reset states if necessary
  private checkAppVersion(): void {
    const storedVersion = localStorage.getItem('appVersion');
    if (storedVersion !== this.appVersion) {
      localStorage.setItem('appVersion', this.appVersion);
      localStorage.removeItem('buttonStates'); // Reset all button states
    }
  }

  // Get the state of a specific button by ID
  getButtonState(buttonId: string): boolean {
    const buttonStates = this.getButtonStates();
    return buttonStates[buttonId] || false;
  }

  // Set the state of a specific button by ID
  setButtonState(buttonId: string, state: boolean): void {
    const buttonStates = this.getButtonStates();
    buttonStates[buttonId] = state;
    localStorage.setItem('buttonStates', JSON.stringify(buttonStates));
  }

  // Retrieve all button states from local storage
  private getButtonStates(): { [key: string]: boolean } {
    const buttonStates = localStorage.getItem('buttonStates');
    return buttonStates ? JSON.parse(buttonStates) : {};
  }
}
