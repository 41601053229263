import {Component, OnDestroy} from '@angular/core';
import {AsyncPipe, DatePipe, NgOptimizedImage} from "@angular/common";
import {RadioButtonModule} from "primeng/radiobutton";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {CheckboxModule} from "primeng/checkbox";
import {DropdownModule} from "primeng/dropdown";
import {InlineSVGModule} from "ng-inline-svg-2";
import {Title} from "../../../shared/enums/title.enum";
import {ContactUtilsService} from "../../../shared/services/contact-utils.service";
import {ActivatedRoute} from "@angular/router";
import {GdprService} from "../../../shared/services/gdpr/gdpr.service";
import {Subject, Subscription} from "rxjs";
import {PostGdprResponseModel} from "../../../shared/models/gdpr/post-gdpr-response.model";
import {GdprFormControlNameEnum} from "../../../shared/enums/gdpr/gdpr-form-control-name.enum";
import {ValidationType} from "../../../shared/enums/global/validation-type.enum";
import {InputValidationErrorMessageEnum} from "../../../shared/enums/global/input-validation-error-message.enum";
import {InfoCardComponent} from "../../../shared/components/info-card/info-card.component";
import {InfoCardConfigModel, InfoCardTextConfig} from "../../../shared/models/global/info-card-config.model";
import {ModalType} from "../../../shared/enums/global/modal-type.enum";
import {GdprResponseModel} from "../../../shared/models/gdpr/gdpr-response.model";
import {CrmExceptionCodeEnum} from "../../../shared/enums/global/crm-exception-code.enum";
import {GdprContactModel} from "../../../shared/modals/gdpr/gdpr-contact.model";
import {EnumLabelValueInterface} from "../../../shared/interfaces/enum-label-value.interface";
import transformAndSortEnum from "../../../shared/utils/transform-and-sort-enum.utils";
import {HonorificTitlePipe} from "../../../shared/pipes/honorific-title.pipe";

@Component({
  selector: 'crm-gdpr-consent-form',
  standalone: true,
  imports: [
    NgOptimizedImage,
    DatePipe,
    RadioButtonModule,
    ReactiveFormsModule,
    CheckboxModule,
    DropdownModule,
    InlineSVGModule,
    // Components
    InfoCardComponent,
    // Pipes
    AsyncPipe
  ],
  providers: [ContactUtilsService, GdprService],
  templateUrl: './gdpr-consent-form.component.html',
  styleUrl: './gdpr-consent-form.component.scss'
})

export class GdprConsentFormComponent implements OnDestroy {
  // today date to get the current year
  today: Date = new Date();
  // GDPR consent form
  gdprConsentForm: FormGroup;
  // title options
  titleOptions: EnumLabelValueInterface<Title>[] = transformAndSortEnum(Title, HonorificTitlePipe);
  // GDPR request token
  token: string;
  // Subs
  subs: Subscription[] = [];
  // subject used to show the card after submitting the form or if the token is invalid
  showInfoCard: Subject<boolean> = new Subject<boolean>();
  infoCardConfig: InfoCardConfigModel;

  constructor(
    private readonly contactUtilsService: ContactUtilsService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly gdprRequestService: GdprService
  ) {
    // Create the GDPR consent form group.
    this.gdprConsentForm = new FormGroup(
      {
        dotDigitalConsent: new FormControl<boolean>(null, Validators.required),
        atozNewsConsent: new FormControl(false),
        atozEventsConsent: new FormControl(false),
        devecoConsent: new FormControl<boolean>(null),
        devecoNewsConsent: new FormControl(false),
        devecoEventsConsent: new FormControl(false),
        title: new FormControl(null),
        firstName: new FormControl(null),
        lastName: new FormControl(null),
        jobTitle: new FormControl(null),
        companyName: new FormControl(null),
      }
    );

    // Subscribing to changes in the 'dotDigitalConsent' control
    this.subs.push(
      this.gdprConsentForm.get('dotDigitalConsent')?.valueChanges.subscribe((value) => {
        const devecoConsentControl = this.gdprConsentForm.get('devecoConsent');
        if (value) {
          // If 'dotDigitalConsent' is checked (true), 'devecoConsent' becomes required
          devecoConsentControl?.setValidators(Validators.required);
        } else {
          // If unchecked (false), remove the required validator
          devecoConsentControl?.clearValidators();
        }
        // Ensure the form control updates its validation state
        devecoConsentControl?.updateValueAndValidity();
      })
    );
    // Get the GDPR request token from the route parameters.
    this.token = this.activatedRoute.snapshot.queryParams['gdpr_token'] || null;
    // Check the validity of GDPR token
    this.subs.push(
      this.gdprRequestService.verifyGDPRRequestToken(this.token).subscribe(
        {
          next : () => {
            // if token is valid, show the GDPR form instead of the card
            this.showInfoCard.next(false);
            // add a subscription disabling the GDPR response form fields
            // if the user doesn't give consent to dot digital
            this.dotDigitalConsentChangesSub();
          },
          error : (err) => this.showErrorAlert(err)
        }
      )
    );
    // Retrieve contact information via token to pre-fill the form
    this.loadContactData();
    this.onDevecoConsentChanges();
  }

  /**
   * Method for retrieving contact information and pre-filling the form
   */
  loadContactData() {
    if (this.token) {
      this.subs.push(
        this.gdprRequestService.getContactByToken(this.token).subscribe({
          next: (contact: GdprContactModel) => {
            // Pre-fill the form with the retrieved contact data
            this.gdprConsentForm.patchValue({
              title: contact.title,
              firstName: contact.firstName,
              lastName: contact.lastName,
              jobTitle: contact.jobTitle,
              companyName: contact.companyName !== 'Not applicable' ? contact.companyName : ''
            });
          },
          error: (err) => {
            this.showErrorAlert(err);
          }
        })
      );
    }
  }
  /**
   * Submits the GDPR consent form and shows validation errors on concerned fields if the form is invalid.
   */
  submit() {
    // make the form controls touched to trigger the validation of each one
    if (this.gdprConsentForm.invalid) {
      this.contactUtilsService.makeFormControlsAsTouched( this.gdprConsentForm );
      return;
    }
    // save GDPR consent form
    const postGDPRResponse = new PostGdprResponseModel(
      this.token,
      this.gdprConsentForm.value as GdprResponseModel
    );
    this.subs.push(
      this.gdprRequestService.submitGDPRPublicForm( postGDPRResponse )
        .subscribe(
          {
            next: () => this.showSuccessAlert(),
            error: (err) => this.showErrorAlert(err)
          }
        )
    );
  }

  /**
   * Checks if a specific form control has a validation error of a given type.
   *
   * @param controlName  The name of the form control to check.
   * @param validationType The type of validation error to check for (e.g., 'required', 'minlength').
   * @returns A boolean indicating whether the specified form control has the specified validation error.
   */
  isControlHasError(
    controlName: string,
    validationType: string
  ): boolean {
    return this.contactUtilsService.isControlHasError(
      this.gdprConsentForm,
      controlName,
      validationType
    );
  }

  /**
   * Returns the form control with the given name from the GDPR form group
   * @param controlName the name of the control
   */
  getFormControl(controlName: string): FormControl {
    return this.gdprConsentForm.get(controlName) as FormControl;
  }

  /**
   * Subscribes to dotDigitalConsent radio button changes and disables the GDPR response form fields accordingly.
   * if dotDigitalConsent is given (true) then the GDPR response form fields are enabled.
   * if dotDigitalConsent is not given (not) then the GDPR response form fields are disabled.
   */
  dotDigitalConsentChangesSub() {
    this.subs.push(
      this.getFormControl(GdprFormControlNameEnum.DOT_DIGITAL_CONSENT).valueChanges.subscribe(
        {
          next: (dotDigitalConsent: boolean) => {
            if (!dotDigitalConsent) {
              this.getFormControl(GdprFormControlNameEnum.ATOZ_NEWS_CONSENT).reset();
              this.getFormControl(GdprFormControlNameEnum.ATOZ_EVENTS_CONSENT).reset();
              this.getFormControl(GdprFormControlNameEnum.DEVECO_CONSENT).reset();
              this.getFormControl(GdprFormControlNameEnum.DEVECO_NEWS_CONSENT).reset();
              this.getFormControl(GdprFormControlNameEnum.DEVECO_EVENTS_CONSENT).reset();
            }
          }
        }
      )
    );
  }

  /**
   * Handles the changes in the Deveco consent checkbox.
   * When the Deveco consent checkbox is unchecked, it resets and disables the Deveco news and events checkboxes.
   * When the Deveco consent checkbox is checked, it enables the Deveco news and events checkboxes.
   */
  onDevecoConsentChanges() {
    this.subs.push(
      // Subscribe to the value changes of the Deveco consent checkbox
      this.getFormControl(GdprFormControlNameEnum.DEVECO_CONSENT)?.valueChanges.subscribe((value) => {
        if (!value) {
          // Reset and disable the Deveco news and events checkboxes when Deveco consent is unchecked
          this.getFormControl(GdprFormControlNameEnum.DEVECO_NEWS_CONSENT).setValue(false);
          this.getFormControl(GdprFormControlNameEnum.DEVECO_EVENTS_CONSENT).setValue(false);
        }
      })
    );
  }

  /**
   * Determines whether the submit button for the GDPR consent form should be disabled.
   * The submit button is disabled if either the dotDigitalConsent or devecoConsent form controls are null.
   *
   * @returns {boolean} - Returns true if either the dotDigitalConsent or devecoConsent form controls are null,
   * indicating that the user has not yet made a selection for these fields.
   * Returns false if both form controls have a value, indicating that the user has made a selection for both fields.
   */
  shouldDisableSubmitButton(): boolean {
    return this.getFormControl(GdprFormControlNameEnum.DOT_DIGITAL_CONSENT).value == null
  }

  /**
   * Show the success alert
   */
  showSuccessAlert() {
    // if token is invalid, show danger alert
    this.showInfoCard.next(true);
    // set the error card config
    this.infoCardConfig = new InfoCardConfigModel(
      ModalType.SUCCESS,
      new InfoCardTextConfig('Thank you ! Your preferences have been saved.', '29px'),
      new InfoCardTextConfig(
        `We’ve successfully saved your preferences. You’ll now receive
      the selected updates from ATOZ straight to your inbox. If you ever wish to update your preferences,
      feel free to revisit our subscription page.`,
        '14px'
      )
    );
  }

  /**
   * Shows the appropriate alert according to the CRM exception code.
   * CRM exception code = 5003 => the GDPR form already submitted.
   * Otherwise, for token invalid/expired exception, it shows an error alert
   * @param err error object
   */
  showErrorAlert(err: any) {
    // if token is invalid, show danger alert
    this.showInfoCard.next(true);
    // Check if the form is already submitted
    if (err.error.code == CrmExceptionCodeEnum.GDPR_REQUEST_ALREADY_SUBMITTED) {
      this.infoCardConfig = new InfoCardConfigModel(
        ModalType.SUCCESS,
        new InfoCardTextConfig('Preferences Already Submitted.', '29px'),
        new InfoCardTextConfig(
          `It looks like you’ve already submitted your preferences,
                 and they’ve been successfully saved.
                 You’re all set to receive the updates you’ve selected from ATOZ.<br/><br/>
              If you’d like to update your preferences or have any questions,
              feel free to reach out to us at atoz_marketing@atoz.lu, and we’ll be happy to assist you.`,
          '14px'
        )
      );
    } else {
      // set the error card config
      this.infoCardConfig = new InfoCardConfigModel(
        ModalType.ERROR,
        new InfoCardTextConfig('Oops! Something went wrong.', '29px'),
        new InfoCardTextConfig(
          `We’ve encountered an issue while processing your request.
              The ATOZ Solutions team has already been notified and will reach out to you once the issue is resolved.
              There’s no need to take further action. We appreciate your patience.<br/><br/>
              If you have any urgent concerns, feel free to contact us at atoz_marketing@atoz.lu`,
          '14px'
        )
      );
    }
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  protected readonly GdprFormControlNameEnum = GdprFormControlNameEnum;
  protected readonly ValidationType = ValidationType;
  protected readonly InputValidationErrorMessageEnum = InputValidationErrorMessageEnum;
}
