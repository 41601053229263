<crm-generic-creat-modal
  [title]="title"
  [description]="description"
>

  <div modal-content>
    <div markdown class="custom-md-font" style="margin-top: -24px !important"
      [src]="'assets/data/changelog.md'">
    </div>
  </div>

  <div modal-footer>
    <div class="flex flex-row-reverse">
      <button (click)="dismissModal()" class="btn-primary btn-positioning" type="button">Close</button>
    </div>
  </div>

</crm-generic-creat-modal>
