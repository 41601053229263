<div class="fixed top-0 left-0 z-50 h-screen w-[240px] flex flex-col bg-neutral-0 border-r-[1px] border-r-neutral-300">
  <div class="h-[100px] flex pl-[16px] px-[12px]">
    <img ngSrc="assets/logo-v2.svg" alt="CRM Logo" height="32" width="134" priority>
  </div>
  <div class="w-full h-full flex flex-col justify-between">
    <div class="h-full flex flex-col justify-between">
      <div class="flex flex-col gap-2 px-[12px]">
          <a class="nav-link !justify-between" routerLink="dashboard" routerLinkActive="bg-neutral-100">
            <div class="flex justify-start items-center gap-3">
              <span inlineSVG="assets/icons/dashboard.svg"></span>
              <p>Dashboard</p>
            </div>
          </a>
        <div class="flex flex-col">
          <a (click)="toggleSublist(sublist, arrowIcon)" class="flex justify-between items-center rounded-md
           hover:bg-neutral-100 px-3.5 h-[40px] transition-colors cursor-pointer">
            <div class="flex justify-start items-center gap-3">
              <span inlineSVG="assets/icons/contact.svg"></span>
              <p class="text-base text-primary-900 font-medium">Contacts</p>
            </div>
            <span inlineSVG="assets/icons/arrow.svg" style="--width: 12px; --color: #6F94A3"
                  class="transition-transform duration-300 mr-1 mt-0.5 custom-icon" #arrowIcon></span>
          </a>
          <div class="flex flex-col gap-2 border-l-neutral-200 border-l-[1px] ml-7 mt-2 pl-2 overflow-hidden
              max-h-96 customTransition" #sublist>
            <a class="nav-link" routerLink="contacts/all" routerLinkActive="bg-neutral-100">
              <span inlineSVG="assets/icons/users-2.svg"></span>
              <p>All</p>
            </a>
            <a class="nav-link" routerLink="contacts/lists" routerLinkActive="bg-neutral-100">
              <span inlineSVG="assets/icons/list-plus.svg"></span>
              <p>Lists</p>
            </a>
            <a class="nav-link" routerLink="contacts/review" routerLinkActive="bg-neutral-100">
              <span inlineSVG="assets/icons/review.svg"></span>
              <p>Lotus import</p>
            </a>
          </div>
        </div>
        <a class="nav-link" routerLink="meetings" routerLinkActive="bg-neutral-100">
          <span inlineSVG="assets/icons/calendar.svg"></span>
          <p>Meetings & Events</p>
        </a>
        <a class="nav-link" routerLink="companies" routerLinkActive="bg-neutral-100">
          <span inlineSVG="assets/icons/building.svg"></span>
          <p>Companies</p>
        </a>
        <a class="nav-link" routerLink="groups" routerLinkActive="bg-neutral-100">
          <span inlineSVG="assets/icons/atom.svg"></span>
          <p>Groups</p>
        </a>
        @if ((currentUser$ | async)?.person.role === UserRole.ADMIN) {
          <a class="nav-link" routerLink="administration" routerLinkActive="bg-neutral-100">
            <span inlineSVG="assets/icons/settings.svg"></span>
            <p>Administration</p>
          </a>
        }
      </div>
    </div>
    <div class="w-full flex flex-col justify-between border-t-[1px] border-neutral-200 py-[12px]">
      <div class="h-full flex flex-col justify-between">
        <div class="flex flex-col gap-2 px-[12px]">
          <a class="nav-link !justify-between" (click)="onSupportButtonClick()"
             [class.bg-neutral-100]="isZendeskOpen">
            <div class="flex justify-start items-center gap-3">
              <span inlineSVG="assets/icons/messages-square.svg"></span>
              <p>Help & Support</p>
            </div>
            <span *ngIf="(unreadMessages | async) as unreadMessagesValue"
                  class="text-[10px] text-red-800 font-semibold bg-red-100 rounded-[4px] py-[2px] px-[4px] min-w-[16px] text-center">
              {{ unreadMessagesValue }}
            </span>
          </a>
          <a class="nav-link" routerLink="learn-more" routerLinkActive="bg-neutral-100">
            <span inlineSVG="assets/icons/help-circle.svg"></span>
            <p>Learn more</p>
          </a>
          <a class="nav-link !justify-between" (click)="openChangelogFeature()">
            <div class="flex justify-start items-center gap-3">
              <span inlineSVG="assets/icons/code.svg"></span>
              <p>What's new</p>
            </div>
            @if (shouldDisplayNewBadge('changelog')) {
              <span class="text-[10px] text-violet-800 font-semibold bg-violet-100 rounded-[4px] py-[2px] px-[4px]">
              NEW
            </span>
            }
          </a>
        </div>
      </div>
    </div>
    <div class="flex items-center justify-between border-t-[1px] border-neutral-200 pl-[12px] pr-[8px] py-[16px]">
      @if (currentUser$ | async; as user) {
        <div class="flex items-center gap-[8px]">
          <crm-avatar-singular [selectedUser]="getCurrentUserAvatar()" [static]="true" [showNameTooltip]="false"/>
          <div class="flex flex-col text-primary-900 w-[130px]">
            <p class="font-semibold truncate" [pTooltip]="user.person.getFullName()" tooltipPosition="top"
               [tooltipDisabled]="!utilsService.showTooltip(user.person.getFullName(), 10.80)">
              {{ user.person.getFullName() }}
            </p>
            <p class="text-neutral-700">{{ user.person.role | titlecase }}</p>
          </div>
        </div>
      }
      <button class="btn-ghost" pTooltip="Logout" tooltipPosition="top" [positionTop]="-5" (click)="logout()">
        <span inlineSVG="assets/icons/log-out.svg"></span>
      </button>
    </div>
  </div>
</div>
