import {environment} from "../../environments/environment";
import {LogLevel} from "angular-auth-oidc-client";
import {PassedInitialConfig} from "angular-auth-oidc-client/lib/auth-config";

export const authConfig : PassedInitialConfig = {
  config: {
    authority: environment.issuer,
    redirectUrl: `${window.location.origin}/callback`,
    postLogoutRedirectUri: window.location.origin,
    clientId: 'crm-web',
    scope: 'openid profile email offline_access',
    responseType: 'code',
    silentRenew: true,
    useRefreshToken: true,
    ignoreNonceAfterRefresh: true,
    renewTimeBeforeTokenExpiresInSeconds:60,
    logLevel: LogLevel.None,
    postLoginRoute: '/contacts/all',
    historyCleanupOff: false,
    secureRoutes: [environment.apiUrl]
  }
}
