import {inject} from '@angular/core';
import { Router, CanActivateFn } from '@angular/router';
import { AuthService } from "../../auth/auth.service";
import { UserRole } from "../enums/user-role.enum";
import {map} from "rxjs";

export const adminGuard: CanActivateFn = () => {
  const authService = inject(AuthService);
  const router = inject(Router);

  return authService.getAuthenticatedUser().pipe(
    map((user) => {
      if (user?.person?.role === UserRole.ADMIN) {
        return true;
      }
      router.navigate(['/contacts/all']);
      return false;
    })
  );
};
