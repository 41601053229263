import {Component} from '@angular/core';
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {GenericCreateModalComponent} from "../generic-create-modal/generic-create-modal.component";
import {HttpClient} from "@angular/common/http";
import {MarkdownComponent, provideMarkdown} from 'ngx-markdown';

@Component({
  selector: 'crm-changelog-modal',
  standalone: true,
  imports: [
    GenericCreateModalComponent,
    MarkdownComponent
  ],
  providers: [
    DialogService,
    provideMarkdown({
      loader: HttpClient
    })
  ],
  templateUrl: './changelog-modal.component.html',
  styleUrl: './changelog-modal.component.scss'
})
export class ChangelogModalComponent {
  title: string = "What's New in CRMAtoz";
  description: string = "Added features and bug fixes.";

  constructor(private ref: DynamicDialogRef,) {
  }

  dismissModal() {
    this.ref.close();
  }
}
