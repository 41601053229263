import {Component, OnInit} from '@angular/core';
import {RouterOutlet} from '@angular/router';
import {OidcSecurityService} from "angular-auth-oidc-client";
import {AuthService} from "./auth/auth.service";
import {concatMap, of} from "rxjs";
import {User} from "./shared/models/user.model";
import {APP_VERSION} from "./version";

@Component({
  selector: 'crm-root',
  standalone: true,
  imports: [RouterOutlet],
  providers: [],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  constructor(private readonly oidcSecurityService: OidcSecurityService,
              private readonly authService: AuthService) {
    this.oidcSecurityService
      .checkAuth()
      .pipe(concatMap(({isAuthenticated, userData, accessToken, idToken, configId}) => {
        if (isAuthenticated)
          return authService.getAuthenticatedUser();
        else
          return of(null);
      }))
      .subscribe((res: User | null) => {
        //console.log('callback authenticated', res);
      });

    if (APP_VERSION === '2.4') {
      // Apply only on this version, fix bug that hide fullName column in contacts all table
      this.fixFullNameColumn();
    }
  }

  ngOnInit(): void {
    window.onload = event => {
      document.getElementById("splash-screen").style.opacity = '0';
    }
    document.getElementById("splash-screen").addEventListener('transitionend', e => {
      document.getElementById("splash-screen").style.display = 'none';
    })
  }

  private fixFullNameColumn(){
    const keysToCheck: string[] = [
      "COLUMNS_FOR_ACTIVE",
      "COLUMNS_FOR_PENDING_DATA_ENTRY",
      "COLUMNS_FOR_PENDING_VALIDATION",
      "COLUMNS_FOR_CONTACTS",
      "COLUMNS_FOR_CONTACT_LIST"
    ];

    keysToCheck.forEach(key => {
      const value = localStorage.getItem(key);
      if (value?.includes("firstName")) {
        localStorage.setItem(key, value.replace("firstName", "fullName"));
      }
    });
  }
}
